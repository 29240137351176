import { h, Component } from 'preact';
import styles from './styles.scss';
import { Link } from '../../shared/link';
import { TopicFlyout } from '../../shared/topic-flyout';
import { Flyout, FlyoutToggle } from '../../shared/flyout';
import { breakpointLarge } from '../../sass/variables.scss';
import { UseCaretIcon } from '../../shared/svg/icons/caret';
import { withTheme } from '../../shared/withTheme';
import { I18n, Config } from '../../shared/context';

function enableFlyout() {
  const { matches } = window.matchMedia(`(min-width:${breakpointLarge})`);
  return !!matches;
}

const TopicLink = withTheme(({ theme, flyout = null, ...props }) => {
  if (flyout) {
    return (
      <Config.Consumer>
        {config => (
          <Flyout width={420} enabled={enableFlyout} location={'topicBar'} label={props.label} ga={config.ga}>
            <FlyoutToggle>
              <Link location={'topicBar'} className={`${styles.link} ${theme.link}`} {...props}>
                {props.emoji} {props.children}
                <UseCaretIcon className={styles.downCaret} aria-hidden={true} />
              </Link>
            </FlyoutToggle>
            <TopicFlyout location={props.label} {...flyout} />
          </Flyout>
        )}
      </Config.Consumer>
    );
  }
  return <Link location={'topicBar'} className={`${styles.link} ${theme.link} ${props.mobileOnly ? styles.mdHide : ''}`} {...props} />;
});

class TopicNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedItems: [...props.topics],
    };
  }

  render({ theme }, state) {
    return <I18n.Consumer>
      { i18n =>
        <nav className={`${theme.topicNavWrapper} ${styles.wrapper}`} aria-label={i18n.hot_topics}>
          <ul className={styles.topicNav} ref={el => this.element = el}>
            {
              state.displayedItems.map(({ url, name, label, ...data }) => (
                <li className={`${styles.topicNavItem} ${data.isMobile ? styles.mobileItem : styles.desktopItem}`} key={name}>
                  <TopicLink href={url} label={label || name} {...data}>{name}</TopicLink>
                </li>
              ))
            }
          </ul>
        </nav>
      }
    </I18n.Consumer>;
  }
}

export const ThemedTopicNav = withTheme(TopicNav);
